import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ProjectsList from '../components/lists/ProjectsList';

import RegularPage from '../styles/regularPage';

const StyledArticle = styled(RegularPage)`
	.intro-section {
		padding: 0;
	}

	.projects-section {
		max-width: 1550px;
		margin: 0 auto 5rem;
	}
`;

export const OurWorkPageTemplate = ({ title }) => (
	<StyledArticle>
		<section className="section intro-section">
			<div className="container">
				<div className="columns">
					<div className="column">
						<h1 className="visually-hidden">{title}</h1>
					</div>
				</div>
			</div>
		</section>
		<section className="section projects-section">
			<ProjectsList />
		</section>
	</StyledArticle>
);

OurWorkPageTemplate.propTypes = {
	title: PropTypes.string,
};

const OurWorkPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout background="light">
			<SEO title={frontmatter.title} />
			<OurWorkPageTemplate title={frontmatter.title} />
		</Layout>
	);
};

OurWorkPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default OurWorkPage;

export const ourWorkPageQuery = graphql`
	query OurWorkPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
			}
		}
	}
`;
