import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledProject = styled.article`
	&.columns:not(:last-child) {
		margin-bottom: 5rem;

		@media (min-width: 1100px) {
			margin: 0 3.55rem 5rem;
		}
	}

	.project-details-column {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.lightest};

		.project-details {
			min-height: 40rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			padding: 5rem 3rem;

			.intro {
				a {
					h2 {
						font-family: ${(props) => props.theme.fonts.sans};
						font-weight: ${(props) => props.theme.fonts.weights.light};
						font-size: 2.6rem;
						letter-spacing: 0.046em;
						line-height: 1;
						color: ${(props) => props.theme.colors.lightest};
						transition: 300ms;
						text-transform: uppercase;
						margin-bottom: 1rem;
					}

					&:hover {
						color: ${(props) => props.theme.colors.inputText};
					}
				}
			}

			.intro {
				/* TODO: see about making this mobile-only, maybe */
				margin-bottom: 4rem;
			}
		}
	}

	.image-column {
		padding: 0;
		a {
			overflow: hidden;
			display: block;
			height: 100%;

			.project-background {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				height: 100%;
				transition: 600ms ease-in-out;

				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;

		&.columns:not(:last-child) {
			margin-bottom: 4rem;
		}

		.project-details-column {
			order: 2;

			.project-details {
				min-height: 15rem;
				padding: 3rem;

				.intro {
					a {
						h2 {
							font-size: 3rem;
						}
					}
				}
			}
		}

		.image-column {
			min-height: 30rem;
			order: 1;

			.project-background {
				min-height: 30rem;
			}
		}
	}
`;

const ProjectCard = ({ id, data }) => {
	const { frontmatter: project } = data;

	return (
		<StyledProject id={id} className="columns">
			<div className="column is-4 project-details-column">
				<div className="project-details">
					<div className="intro">
						<Link to={data.fields.slug}>
							<h2>{project.title}</h2>
						</Link>
					</div>
					<Link className="button button-2" to={data.fields.slug}>
						View
					</Link>
				</div>
			</div>
			<div className="column is-8 image-column">
				<Link to={data.fields.slug}>
					<span className="visually-hidden">View Project: {project.title}</span>
					<div
						className="project-background"
						style={{
							backgroundImage: `url(${project.featured_image.image.childImageSharp.fluid.src})`,
						}}
					></div>
				</Link>
			</div>
		</StyledProject>
	);
};

export default ProjectCard;
