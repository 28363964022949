import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ShowMore from '@tedconf/react-show-more';
import slugify from 'slugify';

import ProjectCard from '../cards/ProjectCard';

const ProjectsList = () => {
	const showMoreBy = 3;
	const [listPosition, setListPosition] = useState(undefined);

	const keepListPlace = (items) => {
		const numItems = items.length;
		setListPosition(numItems);
		sessionStorage.setItem('pnrProjectsListPosition', numItems);
	};

	useEffect(() => {
		const projectsListPosition = parseInt(
			sessionStorage.getItem('pnrProjectsListPosition')
		);

		if (projectsListPosition) {
			setListPosition(projectsListPosition);
		}
	}, []);

	const data = useStaticQuery(graphql`
		query ProjectsListQuery {
			allMarkdownRemark(
				filter: { frontmatter: { template_key: { eq: "project" } } }
				sort: { fields: frontmatter___date, order: DESC }
			) {
				edges {
					node {
						fields {
							slug
						}
						frontmatter {
							title
							featured_image {
								alt
								image {
									childImageSharp {
										fluid(maxWidth: 1200) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const { edges: projects } = data.allMarkdownRemark;

	return (
		<ShowMore items={projects} by={showMoreBy}>
			{({ current, onMore, all }) => {
				if (listPosition !== undefined) {
					const combinedPosition = listPosition + showMoreBy;
					current = all.slice(0, combinedPosition);
				}

				if (current.length >= all.length) {
					onMore = null;
				}

				return (
					<>
						{current.map(({ node }, i) => {
							const slug = slugify(node.fields.slug);
							return (
								<ProjectCard
									id={slug}
									data={node}
									key={`${node.frontmatter.title}-${i}`}
								/>
							);
						})}
						<div className="has-text-centered">
							<button
								className="button load-more"
								disabled={!onMore}
								onClick={() => {
									if (!!onMore) {
										keepListPlace(current);
										onMore();
									}
								}}
							>
								Load More +
							</button>
						</div>
					</>
				);
			}}
		</ShowMore>
	);
};

export default ProjectsList;
