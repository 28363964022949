import styled from 'styled-components';

export default styled.article`
	@media (max-width: 1023px) {
		margin-top: 15rem;
	}

	@media (min-width: 1024px) {
		margin-top: 20rem;
	}
`;
